import { FaDollarSign, FaCheck, FaEye, FaUserCircle } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "config";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../../context";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import React from "react";
import { Select, Option } from "@material-tailwind/react";
import { usePaymentDialogContext } from "components/payment/PaymentDialogContextProvider";
import { Payment } from "config/types";

interface myProps {
  deliveryData: any;
  getDel: () => void;
  getCom: () => void;
}

interface Comment {
  ID: number;
  NoteDescription: string;
  value: string;
  label: string;
  // other properties
}

const Pickup: React.FC<myProps> = ({ deliveryData, getDel, getCom }) => {
  const [completeConfirmDialogVisible, setCompleteConfirmDialogVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(Object);
  const navigate = useNavigate();
  const { state } = useGlobalContext();
  const storage_string = localStorage.getItem("USER");
  const storage = storage_string ? JSON.parse(storage_string) : [];
  const [ios, setIOS] = useState(false);
  const [comment, setComment] = useState<Comment[]>([]);
  const [selected, setSelected] = useState<string>("");

  const [toAddPayment, setToAddPayment] = useState<boolean>(false);

  const userInfoString = localStorage.getItem("USER");
  let userInfo: any;
  if (userInfoString !== null) {
    userInfo = JSON.parse(userInfoString);
  }


  const getComment = async () => {
    const comment = await axios.get(`${baseURL}/quicknotes`, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!comment.data.success) return;
    setComment(comment.data.data);
  };

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return ios;
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      return setIOS(true);
    }
    return ios;
  };

  useEffect(() => {
    getMobileOS();
    getComment();
    // eslint-disable-next-line
  }, []);

  const confirmComplete = async () => {
    var order = {
      ...selectedId,
      Status: "Completed",
      PickedUpby: storage["Name"],
      PickedUpTimestamp: moment(new Date()).format("MM/DD/YY h:mm A")
      // PickupDate: moment(new Date()).format("MM/DD/YY hh:mm")
    };
    const updateOrder = await axios.put(`${baseURL}/order/${selectedId.OrderNumber}`, order, {
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    });
    if (!updateOrder) {
      toast.error("Customer already exist.");
      return;
    } else {
      if (updateOrder.data.success) {
        toast.success(updateOrder.data.message);

        getDel();
        getCom();
        setCompleteConfirmDialogVisible(false);

        if (toAddPayment) {
          moveToAddPayment(selectedId.OrderNumber);
        }
      } else {
        toast.error(updateOrder.data.message);
      }
    }
  };

  const handleChange = async (e: any) => {
    setSelectedId({ ...selectedId, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const moveToOrder = (orderId: any) => {
    navigate(`/orders/${orderId}`);
  };

  const { open: openPaymentDialog } = usePaymentDialogContext();

  const onAfterPaymentAdded = useCallback(
    (payment: Payment) => {
      getDel();
      getCom();
    },
    [getDel, getCom]
  );
  const moveToAddPayment = useCallback(
    (orderId: number) => {
      openPaymentDialog(orderId, onAfterPaymentAdded);
    },
    [openPaymentDialog, onAfterPaymentAdded]
  );

  const parsePhoneNumber = (originPhoneNumber: string) => {
    return (
      originPhoneNumber?.slice(0, 3) +
      "-" +
      originPhoneNumber?.slice(3, 6) +
      "-" +
      originPhoneNumber?.slice(6, 10)
    );
  };

  const handleSelect = (value: string | undefined) => {
    setSelected(value || "");
    setSelectedId({
      ...selectedId,
      DeliveryNotes: selectedId.DeliveryNotes ? `${value} ${selectedId.DeliveryNotes}` : value
    });
  };

  return (
    <div className="flex">
      <div className="panel">
        <div className="flex items-center justify-between panel-header">
          <h3 className="flex items-center font-bold">
            <FaUserCircle className="w-6 h-6 mr-1" />
            Pickups
          </h3>
        </div>
        <div>
          <div className="hidden xl:block">
            <table className="w-full">
              <thead>
                <tr className="">
                  <th className="p-3">#</th>
                  <th className="p-3">
                    Pickup
                    <br />
                    Date
                  </th>
                  <th className="p-3">Product</th>
                  <th className="p-3">
                    Customer
                    <br />
                    Info
                  </th>
                  <th className="p-3 ">
                    Delivery
                    <br />
                    Address
                  </th>
                  <th className="p-3 ">
                    Delivery
                    <br />
                    Contact
                  </th>
                  <th className="p-3 ">
                    Delivered
                    <br />
                    On
                  </th>
                  <th className="p-3">Notes</th>
                  <th className="p-3 ">Balance c</th>
                  <th className="p-3 xl:w-8">Actions</th>
                </tr>
              </thead>
              <tbody className="flex-1 xl:flex-none">
                {deliveryData?.map((item: any, i: number) => (
                  <tr
                    key={i}
                    className={
                      moment(new Date().toISOString()).format("MM/DD/YY") ===
                        moment(item["PickupDate"]).format("MM/DD/YY")
                        ? "flex flex-col mb-2 flex-no wrap xl:table-row xl:mb-0 !bg-[#5DCEF5] "
                        : "flex flex-col mb-2 flex-no wrap xl:table-row xl:mb-0 "
                    }>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["OrderNumber"]}
                    </td>
                    <td
                      className={`
                        ${moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? `p-3 border border-grey-light ${item.isPickUp === true
                            ? "bg-teal-400 hover:bg-teal-600"
                            : "hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          }`
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? `p-3 border border-grey-light  ${item.isPickUp === true
                              ? "bg-teal-400 hover:bg-teal-600"
                              : "hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            }`
                            : `p-3 border border-grey-light ${item.isPickUp === true ? "bg-teal-400 hover:bg-teal-600" : ""
                            }`
                        }`}>
                      {item["PickupDate"] ? moment(item["PickupDate"]).format("MM/DD/YY") : ""}
                      <br />
                      {item.MorningAfternoon}{" "}
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {
                        state.product_list.find(
                          (index) => index.ID === parseInt(item["DumpSterSize"])
                        )?.ItemDescription
                      }
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["CustomerName"] || ""}
                      <br />
                      <Link to={"tel:" + parsePhoneNumber(item["PhoneNumber"])}>
                        {parsePhoneNumber(item["PhoneNumber"])}
                      </Link>
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {!ios ? (
                        <Link to={`https://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                          {item["DeliveryAddress"] || ""}
                        </Link>
                      ) : (
                        <Link to={`maps://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                          {item["DeliveryAddress"] || ""}
                        </Link>
                      )}
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["DeliveryContact"]}
                      <br />
                      <Link to={"tel:" + parsePhoneNumber(item["DeliveryContactPhone"])}>
                        {item["DeliveryContactPhone"]
                          ? parsePhoneNumber(item["DeliveryContactPhone"])
                          : ""}
                      </Link>
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["DeliveredTimestamp"]
                        ? <>
                          <p>{moment(item["DeliveredTimestamp"]).format("MM/DD/YY h:mm A").slice(0, 8)}</p>
                          <p>{moment(item["DeliveredTimestamp"]).format("MM/DD/YY h:mm A").slice(8, 17)}</p>
                        </>
                        : ""}
                      <br />
                      {item["DeliveredBy"]}
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {/* {((item["DeliveryNotes"] ? item["DeliveryNotes"] : "")(item["OrderNotes"] ? item["OrderNotes"] : "")).length} */}
                      {item["DeliveryNotes"] ? item["DeliveryNotes"] : ""}{" "}
                      {item["OrderNotes"] ? item["OrderNotes"] : ""}
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      ${item["BalanceCost"] - item["Discount"]}
                    </td>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      <div className="flex justify-center gap-1">
                        <button
                          className="btn btn-icon bg-green-500"
                          onClick={() => {
                            setCompleteConfirmDialogVisible(true);
                            setSelectedId(item);
                          }}>
                          <FaCheck className="text-white w-4 h-4" />
                        </button>
                        <button
                          className="btn btn-icon bg-gray-500"
                          onClick={() => moveToOrder(item.OrderNumber)}>
                          <FaEye className="text-white w-4 h-4" />
                        </button>
                        <button
                          className="btn btn-icon bg-red-500"
                          onClick={() => moveToAddPayment(item.OrderNumber)}>
                          <FaDollarSign className="text-white w-4 h-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="xl:hidden space-y-1">
            {deliveryData.map((item: any, index: number) => (
              <table key={`delivery-item-${index}-small`} className="w-full">
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>#</th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["OrderNumber"]}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Pickup <br /> Date
                    </th>
                    <td
                      className={`
                        ${moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? `p-3 border border-grey-light ${item.isPickUp === true
                            ? "bg-teal-400 hover:bg-teal-600"
                            : "hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          }`
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? `p-3 border border-grey-light  ${item.isPickUp === true
                              ? "bg-teal-400 hover:bg-teal-600"
                              : "hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            }`
                            : `p-3 border border-grey-light ${item.isPickUp === true ? "bg-teal-400 hover:bg-teal-600" : ""
                            }`
                        }`}>
                      {item["PickupDate"] ? moment(item["PickupDate"]).format("MM/DD/YY") : ""}
                      <br />
                      {item.MorningAfternoon}{" "}
                    </td>
                    {/* <td
                      className={
                        `${item.isPickUp === true ? 'bg-teal-400' : 'bg-gray-20'}
                        ${(moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border-grey-light  hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : "p-3 border-grey-light ")}`
                      }>
                      {item["PickupDate"] ? moment(item["PickupDate"]).format("MM/DD/YY") : ""}
                      <br />
                      {item.MorningAfternoon}{" "}
                    </td> */}
                  </tr>
                  <tr>
                    <th>Product</th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {
                        state.product_list.find(
                          (index) => index.ID === parseInt(item["DumpSterSize"])
                        )?.ItemDescription
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Customer <br /> Info
                    </th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["CustomerName"] || ""}
                      <br />
                      <Link to={"tel:" + parsePhoneNumber(item["PhoneNumber"])}>
                        {parsePhoneNumber(item["PhoneNumber"])}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Delivery <br /> Address
                    </th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {!ios ? (
                        <Link to={`https://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                          {item["DeliveryAddress"]}
                        </Link>
                      ) : (
                        <Link to={`maps://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                          {item["DeliveryAddress"]}
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Delivery <br /> Contact
                    </th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["DeliveryContact"]}
                      <br />
                      <Link to={"tel:" + parsePhoneNumber(item["DeliveryContactPhone"])}>
                        {item["DeliveryContactPhone"]
                          ? parsePhoneNumber(item["DeliveryContactPhone"])
                          : ""}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Delivered <br /> On
                    </th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["DeliveredTimestamp"]
                        ? <>
                          <p>{moment(item["DeliveredTimestamp"]).format("MM/DD/YY h:mm A")}</p>
                        </>
                        : ""}
                      <br />
                      {item["DeliveredBy"]}
                    </td>
                  </tr>
                  <tr>
                    <th>Notes</th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      {item["DeliveryNotes"] ? item["DeliveryNotes"] : ""}{" "}
                      {item["OrderNotes"] ? item["OrderNotes"] : ""}
                    </td>
                  </tr>
                  <tr>
                    <th>Balance</th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      ${item["BalanceCost"]}
                    </td>
                  </tr>
                  <tr>
                    <th>Actions</th>
                    <td
                      className={
                        moment(new Date().toISOString()).format("MM/DD/YY") ===
                          moment(item["PickupDate"]).format("MM/DD/YY")
                          ? "p-3 border border-grey-light hover:!bg-[#05b1eb] !bg-[#5DCEF5]"
                          : moment(new Date().toISOString()).format("MM/DD/YY") >
                            moment(item["PickupDate"]).format("MM/DD/YY")
                            ? "p-3 border border-grey-light hover:!bg-[#ee2b2b] !bg-[#F36969]"
                            : "p-3 border border-grey-light hover:bg-gray-100"
                      }>
                      <div className="flex justify-between gap-1">
                        <button
                          className="btn btn-icon bg-green-500"
                          onClick={() => {
                            setCompleteConfirmDialogVisible(true);
                            setSelectedId(item);
                          }}>
                          <FaCheck className="text-white w-4 h-4" />
                        </button>
                        <button
                          className="btn btn-icon bg-gray-500"
                          onClick={() => moveToOrder(item.OrderNumber)}>
                          <FaEye className="text-white w-4 h-4" />
                        </button>
                        <button
                          className="btn btn-icon bg-red-500"
                          onClick={() => moveToAddPayment(item.OrderNumber)}>
                          <FaDollarSign className="text-white w-4 h-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        </div>
      </div>
      {completeConfirmDialogVisible ? (
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none aria-hidden: focus:outline-none">
            <div className="relative w-2/7 max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <div className="p-6 text-center">
                    <div className="relative justify-between mt-2 ">
                      <div className="p-6 text-center">
                        <svg
                          aria-hidden="true"
                          className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you want to move this contact?
                        </h3>
                        <div className=" relative">
                          <div className="flex justify-between mt-2 ">
                            <div>Did you collect a payment</div>
                            <div>
                              <label className="flex items-center rounded-lg relative w-max cursor-pointer select-none bg-gradient-to-r mr-4 from-gray-200 via-white-500 to-white-500 shadow-inner">
                                <input
                                  className={`appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-50 ${toAddPayment ? "bg-green-500" : " bg-red-600"
                                    }`}
                                  onClick={() => setToAddPayment((val) => !val)}
                                />
                                <span className="absolute font-medium text-xs uppercase right-1 text-white">
                                  {" "}
                                  NO{" "}
                                </span>
                                <span className="absolute font-medium text-xs uppercase right-8 text-white">
                                  {" "}
                                  Yes{" "}
                                </span>
                                <span
                                  className={`${toAddPayment ? "right-0" : "right-7"
                                    } w-7 h-7 absolute rounded-full transform transition-transform bg-gray-200`}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col justify-between mb-5 ">
                          <h3 className="mb-2 text-lg font-bold text-gray-500 dark:text-gray-400">
                            Delivery Comments
                          </h3>
                          <div className="flex justify-around mb-2">
                            <div className="pt-2">Quick Comment</div>
                            <div className="h-10 w-3/5">
                              <Select
                                value={selected}
                                onChange={(value: string | undefined) => handleSelect(value)}>
                                {comment.map((item: any) => (
                                  <Option value={item["NoteDescription"]} key={item["ID"]}>
                                    {item["NoteDescription"]}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <textarea
                            className="w-full h-[120px] rounded-none mt-2"
                            placeholder="Note any additional info related to the delivery..."
                            name="DeliveryNotes"
                            value={selectedId.DeliveryNotes ? selectedId.DeliveryNotes : ""}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={(e) => handleKeyDown(e)}></textarea>
                        </div>
                        <div className="flex justify-between">
                          <button
                            onClick={() => {
                              setCompleteConfirmDialogVisible(false);
                            }}
                            className="btn text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300  dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                            No, cancel
                          </button>
                          <button
                            onClick={() => {
                              confirmComplete();
                            }}
                            className="btn text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium items-center px-5 py-2.5 text-center">
                            Yes, I'm sure
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Pickup;
