import { FaDollarSign, FaEye, FaUserCircle } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import moment from "moment";
import { usePaymentDialogContext } from "components/payment/PaymentDialogContextProvider";
import { Payment } from "config/types";

interface myProps {
  mainData: any;
  getCom: () => void;
  searchKeyword: string;
  handleSearch: (str: string) => void;
}

const Completed: React.FC<myProps> = ({ mainData, getCom, searchKeyword, handleSearch }) => {
  const { state } = useGlobalContext();
  const navigate = useNavigate();
  const [ios, setIOS] = useState(false);

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return ios;
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      return setIOS(true);
    }
    return ios;
  };

  useEffect(() => {
    getMobileOS();
  });

  const parsePhoneNumber = (originPhoneNumber: string) => {
    return (
      originPhoneNumber?.slice(0, 3) +
      "-" +
      originPhoneNumber?.slice(3, 6) +
      "-" +
      originPhoneNumber?.slice(6, 10)
    );
  };

  const moveToOrder = (orderId: any) => {
    navigate(`/orders/${orderId}`);
  };

  const { open: openPaymentDialog } = usePaymentDialogContext();

  const onAfterPaymentAdded = useCallback(
    (payment: Payment) => {
      getCom();
    },
    [getCom]
  );
  const moveToAddPayment = useCallback(
    (orderId: number) => {
      openPaymentDialog(orderId, onAfterPaymentAdded);
    },
    [openPaymentDialog, onAfterPaymentAdded]
  );

  return (
    <div className="flex">
      <div className="panel">
        <div className="flex items-center justify-between panel-header">
          <div>
            <h3 className="flex items-center font-bold">
              <FaUserCircle className="w-6 h-6 mr-1" />
              Completed
            </h3>
          </div>
          <div className="mt-2 mb-1">
            <input
              className="input-inline"
              type="text"
              placeholder="search"
              value={searchKeyword}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="hidden xl:block">
            <table className=" w-full">
              <thead>
                <tr className="flex flex-col mb-2 bg-teal-400 rounded-l-lg flex-no wrap xl:table-row xl:rounded-none xl:mb-0">
                  <th className="p-3">#</th>
                  <th className="p-3 ">Product</th>
                  <th className="p-3  ">
                    Customer
                    <br />
                    Info
                  </th>
                  <th className="p-3 h-[74px] ">
                    Delivery
                    <br />
                    Adress
                  </th>
                  <th className="p-3 h-[74px]">
                    Delivery
                    <br />
                    Contact
                  </th>
                  <th className="p-3 h-[74px]">
                    Delivered
                    <br />
                    On
                  </th>
                  <th className="p-3 h-[74px]">
                    PickedUp
                    <br />
                    On
                  </th>
                  <th className="p-3 ">Notes</th>
                  <th className="p-3">Balance</th>
                  <th className="p-3 xl:w-8">Actions</th>
                </tr>
              </thead>
              <tbody className="flex-1 xl:flex-none">
                {mainData?.map((item: any, i: number) => (
                  <tr key={i} className="flex flex-col mb-2 flex-no wrap xl:table-row xl:mb-0">
                    <td className="p-3 border border-grey-light hover:bg-gray-100">
                      {item["OrderNumber"]}
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100 h-[50px]">
                      {
                        state.product_list.find(
                          (index) => index.ID === parseInt(item["DumpSterSize"])
                        )?.ItemDescription
                      }
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100">
                      {item["CustomerName"]}
                      <br />
                      <Link to={"tel:" + parsePhoneNumber(item["PhoneNumber"])}>
                        {parsePhoneNumber(item["PhoneNumber"])}
                      </Link>
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100">
                      {!ios ? (
                        <Link to={`https://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                          {item["DeliveryAddress"]}
                        </Link>
                      ) : (
                        <Link to={`maps://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                          {item["DeliveryAddress"]}
                        </Link>
                      )}
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100 h-[74px]">
                      {item["DeliveryContact"]}
                      <br />
                      <Link to={"tel:" + parsePhoneNumber(item["DeliveryContactPhone"])}>
                        {item["DeliveryContactPhone"]
                          ? parsePhoneNumber(item["DeliveryContactPhone"])
                          : ""}
                      </Link>
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100 h-[74px]">
                      {item["DeliveredTimestamp"]
                        ? moment(item["DeliveredTimestamp"]).format("MM/DD/YY h:mm A")
                        : ""}
                      <br />
                      {item["DeliveredBy"]}
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100 h-[74px]">
                      {item["PickedUpTimestamp"]
                        ? moment(item["PickedUpTimestamp"]).format("MM/DD/YY h:mm A")
                        : ""}
                      <br />
                      {item["PickedUpby"]}
                      
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100 ">
                      {item["DeliveryNotes"] ? item["DeliveryNotes"] : ""} <br />
                      {item["OrderNotes"] ? item["OrderNotes"] : ""}
                    </td>
                    <td className="p-3 border border-grey-light hover:bg-gray-100">
                    ${item["BalanceCost"] - item["Discount"]}
                    </td>
                    <td className=" border cursor-pointer border-grey-light h-[50px]">
                      <div className="flex justify-center gap-1">
                        <button
                          className="btn btn-icon bg-gray-500"
                          onClick={() => moveToOrder(item.OrderNumber)}>
                          <FaEye className="text-white w-4 h-4" />
                        </button>
                        <button
                          className="btn btn-icon bg-red-500"
                          onClick={() => moveToAddPayment(item.OrderNumber)}>
                          <FaDollarSign className="text-white w-4 h-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="xl:hidden space-y-1">
          {mainData.map((item: any, index: number) => (
            <table key={`delivery-item-${index}-small`} className="w-full">
              <colgroup>
                <col width="30%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>#</th>
                  <td className={"p-3 border border-grey-light hover:bg-gray-100 "}>
                    {item["OrderNumber"]}
                  </td>
                </tr>
                <tr>
                  <th>Product</th>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[50px]">
                    {
                      state.product_list.find(
                        (index) => index.ID === parseInt(item["DumpSterSize"])
                      )?.ItemDescription
                    }
                  </td>
                </tr>
                <tr>
                  <th>
                    Customer <br /> Info
                  </th>
                  <td className="p-3 border border-grey-light hover:bg-gray-100">
                    {item["CustomerName"]}
                    <br />
                    <Link to={"tel:" + parsePhoneNumber(item["PhoneNumber"])}>
                      {parsePhoneNumber(item["PhoneNumber"])}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>
                    Delivery <br /> Address
                  </th>
                  <td className="p-3 border border-grey-light hover:bg-gray-100">
                    {!ios ? (
                      <Link to={`https://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                        {item["DeliveryAddress"]}
                      </Link>
                    ) : (
                      <Link to={`maps://maps.google.com/?q=${item["DeliveryAddress"]}`}>
                        {item["DeliveryAddress"]}
                      </Link>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    Delivery <br /> Contact
                  </th>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[74px]">
                    {item["DeliveryContact"]}
                    <br />
                    <Link to={"tel:" + parsePhoneNumber(item["DeliveryContactPhone"])}>
                      {item["DeliveryContactPhone"]
                        ? parsePhoneNumber(item["DeliveryContactPhone"])
                        : ""}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>
                    Delivered <br /> On
                  </th>
                  <td className={"p-3 border border-grey-light hover:bg-gray-100"}>
                    {item["DeliveredTimestamp"]
                      ? <>
                        <p>{moment(item["DeliveredTimestamp"]).format("MM/DD/YY h:mm A")}</p>
                      </>
                      : ""}
                    <br />
                    {item["DeliveredBy"]}
                  </td>
                </tr>
                <tr>
                  <th>
                    PickedUp <br /> On
                  </th>
                  <td className="p-3 border border-grey-light hover:bg-gray-100 h-[74px]">
                    {item["PickedUpTimestamp"]
                      ? moment(item["PickedUpTimestamp"]).format("MM/DD/YY h:mm A")
                      : ""}
                    <br />
                    <br />
                    {item["PickedUpby"]}
                  </td>
                </tr>
                <tr>
                  <th>Notes</th>
                  <td className={"p-3 border border-grey-light hover:bg-gray-100 "}>
                    {item["OrderNotes"] ? item["OrderNotes"] : ""}
                  </td>
                </tr>
                <tr>
                  <th>Balance</th>
                  <td className={"p-3 border border-grey-light "}>${item["BalanceCost"]}</td>
                </tr>
                <tr>
                  <th>Actions</th>
                  <td className={" border cursor-pointer border-grey-light h-[50px] "}>
                    <div className="flex justify-between gap-1">
                      <button
                        className="btn btn-icon bg-gray-500"
                        onClick={() => moveToOrder(item.OrderNumber)}>
                        <FaEye className="text-white w-4 h-4" />
                      </button>
                      <button
                        className="btn btn-icon bg-red-500"
                        onClick={() => moveToAddPayment(item.OrderNumber)}>
                        <FaDollarSign className="text-white w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Completed;
